<template>
    <div class="about">
            
        <img class="about__bg" :src="require('../../assets/img/icons/logo.svg')" alt="">

        <div class="about__img"></div>
        <div class="about__desc">
            <h1><span class="jojo">j</span>ac<span class="jojo">o</span>po<br> .panzera</h1>
            <p>
                Graphic Designer, illustrator and web developer based in Milan.
                Proactive, teamplayer with good managment skills and well versed to remote projects. 
                Always striving to put love and care in what I do.
                <br><br>
                Curious and creative personality, love to experiment and try
                out new techniques and media.
                Everything in here (including the portfolio itself) has been done by me, one-man-band!
                I hope my work will speak for itself
            </p>
        </div>
        <div class="about__social">
            <a href="https://www.linkedin.com/in/jacopo-panzera-b3a3b1167/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            <a href="https://www.behance.net/panzerajac5508" target="_blank"><i class="fab fa-behance"></i></a>
            <a href="https://www.instagram.com/jac.pan/" target="_blank"><i class="fab fa-instagram"></i></a>
        </div>
    </div>

</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    
</style>