export default [
    
    
    {
        url: 'fungisamurai',
        name: 'Fungi Samurai',
        type: ['draw'],
        desc: 'Concept art, done in photoshop. A lone Fungi Samurai walking aimlessly thru the ancient giant mushroom forest.',
        slides: ['total','head','sketch'],
        preview: false,
        show: true,
    },
    {
        url: 'portrait',
        name: 'Self Portrait',
        type: ['draw'],
        desc: '',
        slides: [],
        preview: false,
        show: true,
    },
    {
        url: 'urdrugs',
        name: 'Know your Drugs',
        type: ['draw'],
        desc: 'Series of vector illustrations born to bring awarness and light on one of the most tabù topic of our society, illegal drugs. <br><br>Often we talk about these substances but most of the time we don’t even know how they look. <br><br>Thorugh weekly Instagram’s posts i tried to bring a visual rapresentation ligthen by a colorfull look, accompanied by a detailed descprition of the drug’s effects and consequences.',
        slides: ['smile', 'weed', 'opioids', 'coca', 'mashroom', 'alcol'],
        preview: true,
        show: true,
    },
    {
        url: 'square',
        name: 'Square Movie Poster',
        type: ['draw'],
        desc: 'Series of vector illustrations. Movie posters forced in the square-instagram format, challenging my creativity and art skils.',
        slides: ['1','2','3','4','5','6','7','8','9','10','11-12','11','12'],
        preview: false,
        show: true,
    },
    {
        url: 'sketch',
        name: 'Sketch Book',
        type: ['draw'],
        desc: 'Instagram project in which I produced one illustration per week. <br><br>It condense my progress over time, the experiments to further elaborate my style and showcase some influences on my work.',
        slides: ['steam','tacos','orono','bulldog','bojack','hilda','fire'],
        preview: true,
        show: true,
    },
    {
        url: '20dicembre',
        name: '20 Dicembre',
        type: ['graphic'],
        desc: 'First Turn Kill - italian trapper mixtape coverart. <br><br>Neon, iconic, nostalgic. Sadness and social distress masked by candy like colors that punch with eachother. A distorted image within a cold setting derived by the title (22 December) that represent an important and intimate season for the artist. <br><br>The emo trap music, directly influenced by an internet vaporwave culture,  is well displayed in this stylistic cover art. It will live primarly digitally on soun cloud.',
        slides: ['soundcloud', 'mockup1', 'snowman', 'mockup2', 'mountain', 'spread', 'fontsystem'],
        preview: true,
        show: true,
    },
    {
        url: 'hrfestival',
        name: 'Hard Rockolo',
        website: 'https://hardrockolo.netlify.app',
        type: ['graphic', 'web'],
        desc: 'Hard Rockolo is a music festival that take place in the middle of the Dolomiti mountains, in my home town. I am part of the organization as media manager, graphic and web designer. <br><br>This project follows my enitre career in graphic design, displaying the progress I made along the way and ispiring me to innovate and better my skills.',
        preview: true,
        show: true,
        slides: ['2018poster', 'stage', 'artposter', 'forte', '2019poster', 'explorer', '2019tshirt', 'sage']
    },
    {
        url: 'mopay', 
        name: 'MoPay', 
        type: ['graphic'],
        desc: 'E-wallet App brand identity. This app let you pay and manage your money accounts through your phone. <br><br>Technology, Reliability, security are the main features requested by the company. <br><br>The technology field in which this logo will leave also has its requirements, it has to be bold, modern and simple.',
        preview: true, 
        show: true,
        slides: ['logo', 'moodboard', 'addition', 'construction', 'sketch', 'table'],
    },
    {
        url: '20coperti', 
        name: 'Venti Coperti', 
        type: ['graphic'],
        desc: 'Corporate identity for Venetian restaurant with a modern spin on traditional dishes. <br><br>The name is a word play between “twenty”, that in Italian also mean “winds”, and “covers” that also means “tables settled”. <br><br>Conceptually it indicates an association between the protection from the ocean winds and the small size of the restaurant.',
        preview: true, 
        show: true,
        slides: ['logo', 'logo_construction', 'sign_mockup', 'sketch', 'pattern', 'card', 'table'],
    },
    {
        url: 'nespresso', 
        name: 'Nespresso Nicaragua', 
        type: ['graphic'],
        desc: 'Passionate and wild, the new taste of coffe born in Nicaragua\'s cultivations for you to taste. Mock up ad, done starting from row assets, polished and assembled to communicate a vibrant and exicting new taste.',
        slides: ['facebook', 'street_mockup', 'poster'],
        preview: false, 
        show: true,
    },
    {
        url: 'tlc',
        name: 'TLC T-shirt', 
        type: ['graphic'],
        desc: 'Company t-shirts for a renewed american stage & special-effects buisness. <br><br>The two designs recall different eras of special effects, with neon lights and lasers constructs.',
        slides: ['female','male','mood','wow','logo'],
        preview: false, 
        show: true,
    },
    {
        url: 'eulost',
        name: 'Europe is lost',
        type: ['graphic'],
        desc: 'Conceptual album cover for the single "Europe is Lost" by the Rapper Kate Tempest. <br><br>It wants to play on the subversion on the very symbol of Europe using *arguably* the opposite geometrical shape of a circle landing on a commy background.',
        slides: [],
        preview: false,
        show: true,
    },
    {
      url: 'whatever', 
      name: 'Cook whatever', 
      website: 'https://cook-whatever.netlify.app',
      type: ['web'],
      desc: 'Plain html5, css, js mockup project for a recipes website. You can only search "pizza" and "tacos" given the database, but who needs more then that?',
      slides: [],
      preview: false, 
      show: true,
    },
]